import React, { useEffect } from 'react';
import gsap from 'gsap';

const useContactAnimation = () => {
  useEffect(() => {
    const contactMenuButton = document.getElementById('contact_menu');
    const closeContactButton = document.getElementById('close_contact');
    const contactPage = document.getElementById('main_contact_id');

    const openContact = () => {
      gsap.fromTo(contactPage, 
        { opacity: 0, zIndex: 0 },
        { opacity: 1, zIndex: 20, duration: 0.5 }
      );
    };

    const closeContact = () => {
      gsap.fromTo(contactPage, 
        { opacity: 1, zIndex: 20 },
        { opacity: 0, zIndex: 0, duration: 0.5 }
      );
    };

    if (contactMenuButton) contactMenuButton.addEventListener('click', openContact);
    if (closeContactButton) closeContactButton.addEventListener('click', closeContact);

    // Cleanup function
    return () => {
      if (contactMenuButton) contactMenuButton.removeEventListener('click', openContact);
      if (closeContactButton) closeContactButton.removeEventListener('click', closeContact);
    };
  }, []);

  return null; // This hook does not return any JSX.
};

export default useContactAnimation;
