import React from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { Plane } from '@react-three/drei';


const ImageComponent = ({ position, imageUrl, scale, opacity }) => {
  const texture = useLoader(TextureLoader, imageUrl, loader => {
    loader.setCrossOrigin('anonymous');
  });

  return (
    <Plane args={[1, 1]} position={position} scale={scale}>
      <meshBasicMaterial attach="material" 
      map={texture} 
      toneMapped={false}
      transparent={true}
      opacity={opacity}
      />
    </Plane>
  );
};

export default ImageComponent;
