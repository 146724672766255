import React from 'react';
import TextComponent from './TextComponent';

const GroupedText = ({ texts, opacity, position }) => {
  return (
    <group position={[position[0], position[1], position[2]]}>
      {texts.map((text, index) => (
        <TextComponent
          key={text.id + index}
          position={[text.position[0], text.position[1], text.position[2]]}
          text={text.text}
          fontSize={text.fontSize}
          fontFamily={text.fontFamily}
          opacity={opacity}
        />
      ))}
    </group>
  );
};

export default GroupedText;
