import React, { useState, useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import TextComponent from '../GeneralComponents/TextComponent';
import ImageComponent from '../GeneralComponents/ImageComponent';
import VideoComponent from '../GeneralComponents/VideoComponent';
import GridComponent from '../GeneralComponents/GridComponent';
import GroupedText from '../GeneralComponents/GroupedText';
import ImageTextComponent from '../GeneralComponents/ImageTextComponent';
import ImageImageComponent from '../GeneralComponents/ImageImageComponent';
import ImageKPYComponent from '../GeneralComponents/ImageKPYComponent';

const SceneComponent = () => {
    const resetPositions = () => {
      scrollArea.current.positionZ = 0;
        setElements(elements.map((el, index) => {
            // Reset position and opacity to initial values
            let newPosition = [el.position[0], el.position[1], el.initialPositionZ];
            let newOpacity = 0;
    
            // Apply fadeIn effect to the first element
            if (index === 0) {
                fadeInElement(el.id);
            }else{
              newOpacity = el.initialOpacity;
            }
            
            return {
                ...el,
                position: newPosition,
                opacity: newOpacity
            };
        }));
    };

    const fadeOutElement = (id, duration = 500) => {
        const element = elements.find(e => e.id === id);
        if (!element) return;
        const interval = 25;
        const fadeOutRate = interval / duration;
        const fadeInterval = setInterval(() => {
          setElements(currentElements => {
            const currentElement = currentElements.find(e => e.id === id);
            if (!currentElement) {
              clearInterval(fadeInterval);
              return currentElements;
            }
            const newOpacity = Math.max(currentElement.opacity - fadeOutRate, 0);
            if (newOpacity === 0) {
              clearInterval(fadeInterval);
            }
            return currentElements.map(el =>
              el.id === id ? { ...el, opacity: newOpacity } : el
            );
          });
        }, interval);
    };

    const fadeInElement = (id, duration = 1000) => {
      const element = elements.find(e => e.id === id);
      if (!element) return;
      const interval = 25;
      const fadeInRate = interval / duration;
      const targetOpacity = element.targetOpacity;
      const fadeInterval = setInterval(() => {
        setElements(currentElements => {
          const currentElement = currentElements.find(e => e.id === id);
          if (!currentElement) {
            clearInterval(fadeInterval);
            return currentElements;
          }
          const newOpacity = Math.min(currentElement.opacity + fadeInRate, targetOpacity);
          if (newOpacity === targetOpacity) {
            clearInterval(fadeInterval);
          }
          return currentElements.map(el =>
            el.id === id ? { ...el, opacity: newOpacity } : el
          );
        });
      }, interval);
    };

  const scrollArea = useRef({ positionZ: 0 });
  const [elements, setElements] = useState([
    { type: 'video', id: 'video_home', move: false, linked:"text_desc", position: [0, 0, -30], initialPositionZ: -30, videoUrl: '/assets/Home.mp4', scale: [30, 45, 1], opacity: 0.85, initialOpacity: 0.85, targetOpacity: 0.85},
    { type: 'text', id: 'text_desc', move: true, linked:"video_home", position: [0, 0, -20], initialPositionZ: -20, fontFamily: "/fonts/SelfModernTextBold.otf", text: 'We design iconic spaces\nled by narrative and\ndriven by data that\nattract, engage and retain\nyour target market.\n\nIt’s at this intersection\nof product and guest\nexperience that we create\ndream projects and\nunlock financial goals.', fontSize: 1.0, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_desc_grid', move: true, position: [0, 10, -40], initialPositionZ: -40, fontFamily: "/fonts/SelfModernTextBold.otf", text: 'Our directors have developed\nfruitful working relationships with\nindustry leading hospitality operators:', fontSize: 1.0, opacity: 0, initialOpacity: 0, targetOpacity: 0 },
    {
      type: 'grid', 
      id: 'grid_projects', 
      move: true, 
      position: [0, 0, -40], 
      initialPositionZ: -40,
      items: [
        { title: 'Bambino', description: 'Restaurant, Bar, Music Venue,\nHo Chi Minh City, Italian', fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Noma*', description: 'Restaurant,  Copenhagen\nMichelin***,  New Nordic', fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Ikoyi*', description: 'Restaurant,  London,  Michelin**\nLondon', fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Above Board', description: "Cocktail Bar,  Melbourne\n#44 World's Best Bars 2021", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Falco', description: "Bakery,  Melbourne\nAustralian Contemporary", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'The Everleigh', description: "Cocktail Bar,  Melbourne\n#38 World's Best Bars 2014", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Capitano', description: "Restaurant,  Bar\nItalian-ish,  Melbourne", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Worksmith', description: "Co-Working,  Hospitality\nMelbourne", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Archie Rose', description: "Distillery,  Bar,  Sydney\nAustralian-Focused", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
        { title: 'Rose Island', description: "Wine Bar,  Melbourne\nItalian", fontSize: 0.4, fontFamily: '/fonts/suisse_int_2.ttf', opacity: 1 },
      ],
      opacity: 0, 
      initialOpacity: 0,
      targetOpacity: 0
    },

    //BAMBINO
    { type: 'grouped_text', id: 'grouped_text_bambino_1', move: true, position: [0, 0, -60], initialPositionZ: -60,
      opacity: 0, initialOpacity: 0, targetOpacity: 1,
      texts: [
        {id: "grouped_text_1", text: "Selected Project", fontFamily: "/fonts/SelfModernTextBold.otf", fontSize: 0.4, position: [0, 1.5, 0]},
        {id: "grouped_text_2", text: "BAMBINO", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 1.7, position: [0, 0, 0]},
        {id: "grouped_text_3", text: "In Saigon, a cosmic renaissance meets designed\nnostalgia at Bambino - an Italian focused\nrestaurant, bar and late night music venue.", fontFamily: "/fonts/SelfModernTextBold.otf", fontSize: 0.4, position: [0, -1.5, 0]},
      ]
    },
    { type: 'grouped_text', id: 'grouped_text_bambino_2', move: true, position: [0, 0, -80], initialPositionZ: -80,
      opacity: 0, initialOpacity: 0, targetOpacity: 1,
      texts: [
        {id: "grouped_text_4", text: "TYPOLOGY", fontFamily: "/fonts/FoundersGrotesk-Light.otf", fontSize: 0.4, position: [0, 4, 0]},
        {id: "grouped_text_5", text: "ITALIAN RESTAURANT + BAR", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, 3.5, 0]},

        {id: "grouped_text_6", text: "LOCATION", fontFamily: "/fonts/FoundersGrotesk-Light.otf", fontSize: 0.4, position: [0, 2.5, 0]},
        {id: "grouped_text_7", text: "HO CHI MINH CITY, VN", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, 2, 0]},

        {id: "grouped_text_8", text: "SIZE", fontFamily: "/fonts/FoundersGrotesk-Light.otf", fontSize: 0.4, position: [0, 1, 0]},
        {id: "grouped_text_9", text: "540M2", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, 0.5, 0]},

        {id: "grouped_text_10", text: "SERVICES", fontFamily: "/fonts/FoundersGrotesk-Light.otf", fontSize: 0.4, position: [0, -0.5, 0]},
        {id: "grouped_text_11", text: "CONCEPT + NARRATIVE", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, -1, 0]},
        {id: "grouped_text_12", text: "INTERIOR ARCHITECTURE", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, -1.5, 0]},
        {id: "grouped_text_13", text: "BRAND CONSULTANCY", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, -2, 0]},
        {id: "grouped_text_14", text: "EXPERIENCE DESIGN", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, -2.5, 0]},

        {id: "grouped_text_15", text: "CLIENT", fontFamily: "/fonts/FoundersGrotesk-Light.otf", fontSize: 0.4, position: [0, -3.5, 0]},
        {id: "grouped_text_16", text: "LEFT OVERS GROUP", fontFamily: "/fonts/FoundersGrotesk-Medium.otf", fontSize: 0.4, position: [0, -4, 0]},
      ]
    },
    
    { type: 'image', id: 'image_bambino_0', move: false, position: [0, 0, -25], imageUrl: '/assets/project_1/LG_Bambino_img_blur.png', scale: [50, 33, 1], initialPositionZ: -25, opacity: 0, initialOpacity: 0, targetOpacity: 0.6 },
    { type: 'image', id: 'image_bambino_1', move: true, position: [0, 0, -100], imageUrl: '/assets/project_1/LG_Bambino_01.jpg', scale: [36, 24, 1], initialPositionZ: -100, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    
    { type: 'text', id: 'text_bambino_1', move: true, position: [0, 0, -120], initialPositionZ: -120, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'In keeping with a brief that\nrequired an Italian dining\nexperience that could seamlessly\ntransition to a bar and late night\nmusic venue, Looks Generous\nhoned in on their Client’s future\nminded values to drive the\nconceptual narrative.', 
    },
    { type: 'image', id: 'image_bambino_2', move: true, position: [0, 0, -140], imageUrl: '/assets/project_1/LG_Bambino_02.jpg', scale: [36, 24, 1], initialPositionZ: -140, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'video', id: 'video_bambino_1', move: true, position: [0, 0, -160], videoUrl: '/assets/project_1/carlo_scarpa.mp4', scale: [15, 15, 1], initialPositionZ: -160, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_2', move: true, position: [0, 0, -180], initialPositionZ: -180, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'Looks Generous looked to Italian\nartistic visionaries to inform the\ngeneral approach to space\nplanning and detailing.\nWe found solace in the design\nphilosophy of mid-century\narchitect Carlo Scarpa —\na rebel who rejected what was\nexpected of him by excluding the\nclassically ornate in favour of the\ncutting edge and futurist\nconstruction methods.', 
    },
    { type: 'image', id: 'image_bambino_4', move: true, position: [0, 0, -200], imageUrl: '/assets/project_1/LG_Bambino_03.jpg', scale: [36, 24, 1], initialPositionZ: -200, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_5', move: true, position: [0, 0, -220], imageUrl: '/assets/project_1/LG_Bambino_04.jpg', scale: [36, 24, 1], initialPositionZ: -220, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'video', id: 'video_bambino_2', move: true, position: [0, 0, -240], videoUrl: '/assets/project_1/daniele_baldelli.mp4', scale: [15, 15, 1], initialPositionZ: -240, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_3', move: true, position: [0, 0, -260], initialPositionZ: -260, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'In parallel with this, Danielle\nBaldelli, a visionary DJ and\nproducer that led the rise of the\ncosmic Italo disco sound from\nnorthern Italy in the 1980s,\ninformed the approach towards\nthe brief’s need for a late-night\nmusic ‘mode’.\n\nTheir forward-thinking ethos and\npassion to evolve their crafts\nbeyond the status quo -\nespecially from within a country\nwhere history and tradition is\neverything - offered the perfect\nmetaphor from which to reflect\nthe values of the Leftovers Group\ninto the Bambino dining\nexperience.', 
    },
    { type: 'image', id: 'image_bambino_7', move: true, position: [0, 0, -280], imageUrl: '/assets/project_1/LG_Bambino_09.jpg', scale: [36, 24, 1], initialPositionZ: -280, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_8', move: true, position: [0, 0, -300], imageUrl: '/assets/project_1/LG_Bambino_IM_03.jpg', scale: [15, 22.5, 1], initialPositionZ: -300, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_4', move: true, position: [0, 0, -320], initialPositionZ: -320, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'From our initial meeting with\nLeftovers Group, we were acutely\naware of their needs and scale of\nambition. Creating a space\nthat delivered from an experience\nperspective, while also delivering\non their financial objectives was\nof equal importance.\n\nDesign is just the medium by\nwhich we help Bambino thrive,\nexperientially, culinarily and\nfinancially.', 
    },
    { type: 'image', id: 'image_bambino_9', move: true, position: [0, 0, -340], imageUrl: '/assets/project_1/LG_Bambino_06.jpg', scale: [36, 24, 1], initialPositionZ: -340, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_10', move: true, position: [0, 0, -360], imageUrl: '/assets/project_1/LG_Bambino_IM_04.jpg', scale: [15, 22.5, 1], initialPositionZ: -360, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_11', move: true, position: [0, 0, -380], imageUrl: '/assets/project_1/LG_Bambino_07.jpg', scale: [36, 24, 1], initialPositionZ: -380, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_5', move: true, position: [0, 0, -400], initialPositionZ: -400, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'An efficient circulation path to\nand from the kitchen isolates\nthe dining space,\nseparating the 10 meter long bar\nand feature wine wall that jostle\nfor guest’s attention.\n\nThe bar is clad in a luxurious\nItalian-inspired palette of\nstained claret hued oak panels,\nchampagne chrome and crimson-\npigmented concrete, all framed\nby a muted-lemon oak panelled\ncanopy that directs the eye\ntowards to the beverage\npreparation.', 
    },
    { type: 'image', id: 'image_bambino_12', move: true, position: [0, 0, -420], imageUrl: '/assets/project_1/LG_Bambino_IM_08.jpg', scale: [15, 22.5, 1], initialPositionZ: -420, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_6', move: true, position: [0, 0, -440], initialPositionZ: -440, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'The wine wall, intended to\nimprove on the traditionally ‘dry’\nservice method of navigating a\nnovel length wine list, affords\nguests the option to select wine\nfrom the label’s visual cues.', 
    },
    { type: 'image', id: 'image_bambino_13', move: true, position: [0, 0, -460], imageUrl: '/assets/project_1/LG_Bambino_IM_06.png', scale: [15, 22.5, 1], initialPositionZ: -460, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_7', move: true, position: [0, 0, -480], initialPositionZ: -480, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'Beyond the restaurant and bar,\na 70sqm rooftop terrace offers\nspace for guests to catch their\nbreath, with leafy views over\nDistrict One that hush the bike\nand car horns that permeate\nthrough Saigon.\n\nStriped orange and white\nbanquettes offer loungier, resort\nstyle seating options, allowing\nfor closer proximity and\nengagement in great\nconversation.', 
    },
    { type: 'image', id: 'image_bambino_14', move: true, position: [0, 0, -500], imageUrl: '/assets/project_1/LG_Bambino_II_01.png', scale: [15, 22.5, 1], initialPositionZ: -500, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_15', move: true, position: [0, 0, -520], imageUrl: '/assets/project_1/LG_Bambino_II_02.jpg', scale: [15, 22.5, 1], initialPositionZ: -520, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'text', id: 'text_bambino_8', move: true, position: [0, 0, -540], initialPositionZ: -540, 
      fontFamily: "/fonts/SelfModernTextBold.otf", 
      fontSize: 0.7, opacity: 0, initialOpacity: 0, targetOpacity: 1,
      text: 'Capping out the Bambino space\nis a 14 seat Private Dining Room\nthat casts an architectural spell\nover the restaurant’s central\nvoid, in a palette contrasting yet\ncomplimenting the rest of\nBambino, anchored by a custom\nceiling lamp referencing the\ndesign language Carlo Scarpa\ngave to the world.', 
    },
    { type: 'image', id: 'image_bambino_16', move: true, position: [0, 0, -560], imageUrl: '/assets/project_1/LG_Bambino_IM_07.jpg', scale: [15, 22.5, 1], initialPositionZ: -560, opacity: 0, initialOpacity: 0, targetOpacity: 1 },
    { type: 'image', id: 'image_bambino_17', move: true, position: [0, 0, -580], imageUrl: '/assets/project_1/LG_Bambino_II_03.jpg', scale: [15, 22.5, 1], initialPositionZ: -580, opacity: 0, initialOpacity: 0, targetOpacity: 1 },

  ]);

  //PC DEFILMENT
  const onWheel = (e) => {
    //Block if contact page open
    let contact_page = document.getElementById("main_contact_id");
    if(contact_page.style.opacity > 0.5){
      return
    }
    //--------------------------

    //Block if contact page open
    let scroll_factor = 0.0007;
    //--------------------------


    const delta = (e.deltaY * scroll_factor);
    const potentialNewPositionsZ = elements.map(el => el.move ? el.position[2] + delta : el.position[2]);
    if (potentialNewPositionsZ[1] <= -20) {
      return;
    }
    scrollArea.current.positionZ += delta;
    setElements(elements.map((el, index) => ({
      ...el,
      position: [el.position[0], el.position[1], potentialNewPositionsZ[index]],
    })));
  };

  useEffect(() => {
    window.addEventListener('wheel', onWheel);
    return () => {
      window.removeEventListener('wheel', onWheel);
    };
  }, [elements]);

  useEffect(() => {
    const loaderElement = document.getElementById('loader_text_enter');
    if (loaderElement) {
      loaderElement.addEventListener('click', resetPositions);
    }

    // Cleanup
    return () => {
      if (loaderElement) {
        loaderElement.removeEventListener('click', resetPositions);
      }
    };
  }, [elements]);

  useFrame(() => {
    scrollArea.current.positionZ *= 0.975;
    setElements(elements.map(el => {
      let newPositionZ = el.move ? el.position[2] + scrollArea.current.positionZ : el.position[2];

      if(el.move && newPositionZ >= -1 && newPositionZ <= -0.5 && el.type !== "image" && el.type !== "video"){
        fadeOutElement(el.id);
      }

      else if(el.move && newPositionZ >= -2 && newPositionZ <= -1.5 && (el.type === "image" || el.type === "video")){
        fadeOutElement(el.id);
      }

      else if(newPositionZ <= el.initialPositionZ){
        //If position of text_desc is <= at z=-20 we stop scrolling it -> Bound
        newPositionZ = el.initialPositionZ;
        if(el.id === "text_desc" && el.opacity >= 0.1){
          fadeOutElement(el.id)
          fadeInElement(el.linked)
        }
      }

      else if (el.move && newPositionZ > -20 && el.opacity !== 1 && newPositionZ < -1.5) {
        //---------------------------------------------------
        //- Independant case
        if(el.id === "grouped_text_bambino_1" || el.id === "grouped_text_bambino_2"){
          fadeInElement("image_bambino_0")
        } 
        else if(el.id === "image_bambino_1" || el.id === "grid_projects"){
          fadeOutElement("image_bambino_0")
        }
        //---------------------------------------------------
        fadeInElement(el.id);
        if(el.linked){
          fadeOutElement(el.linked)
        }
      }
      else if (el.move && newPositionZ <= -20 && el.opacity !== 0) {
        fadeOutElement(el.id);
        if (el.linked){
          fadeInElement(el.linked);
        }
      }
      return {
        ...el,
        position: [el.position[0], el.position[1], newPositionZ],
      };
    }));
  
    if (Math.abs(scrollArea.current.positionZ) < 0.001) {
      scrollArea.current.positionZ = 0;
    }
    
    elements.forEach(movingElement => {
        if (movingElement.move && movingElement.position[2] > 5 && movingElement.position[2] < 6) {
            const linkedElement = elements.find(e => e.id === movingElement.linked);
            if (linkedElement && linkedElement.opacity !== 0) {
                fadeOutElement(linkedElement.id);
            }
        }
    });

    //RESET
    const lastElement = elements[elements.length - 1];
    if (lastElement.position[2] > 1) {
        scrollArea.current.positionZ = 0;
        setElements(elements.map((el, index) => {
            // Reset position and opacity to initial values
            let newPosition = [el.position[0], el.position[1], el.initialPositionZ];
            let newOpacity = 0;
    
            // Apply fadeIn effect to the first element
            if (index === 0) {
                fadeInElement(el.id);
            }else{
              newOpacity = el.initialOpacity;
            }
            
            return {
                ...el,
                position: newPosition,
                opacity: newOpacity
            };
        }));
    }
    });

  //PHONE DEFILMENT
  const [touchStart, setTouchStart] = useState(null);
  const onTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  const onTouchMove = (e) => {
    if (touchStart == null) {
      return;
    }
    //Block if contact page open
    let contact_page = document.getElementById("main_contact_id");
    if(contact_page.style.opacity > 0.5){
      return
    }
    //--------------------------

    const scroll_factor = 0.002

    const currentTouch = e.touches[0].clientY;
    const delta = touchStart - currentTouch;
    setTouchStart(currentTouch);
    scrollArea.current.positionZ += delta * scroll_factor;
    setElements(elements.map(el => {
        const newPositionZ = el.move ? el.position[2] + delta * scroll_factor : el.position[2];
        return {
          ...el,
          position: [el.position[0], el.position[1], newPositionZ],
        };
      }));
  };

  useEffect(() => {
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    return () => {
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
    };
  }, [elements, touchStart]);

  return (
    <>
    <group>
      {elements.map((el, index) => {
        const commonProps = {
          key: el.id,
          position: el.position,
          opacity: el.opacity,
        };

        switch (el.type) {
          case 'text':
            return <TextComponent {...commonProps} text={el.text} fontSize={el.fontSize} fontFamily={el.fontFamily} />;
          case 'image':
            return <ImageComponent {...commonProps} imageUrl={el.imageUrl} scale={el.scale} />;
          case 'video':
            return <VideoComponent {...commonProps} videoUrl={el.videoUrl} scale={el.scale} />;
          case 'grid':
              return <GridComponent {...commonProps} items={el.items} />;
          case 'grouped_text':
                return <GroupedText {...commonProps} texts={el.texts} />;
          case 'image_text':
                  return <ImageTextComponent {...commonProps} 
                                              imageUrl={el.imageUrl}
                                              imageScale={el.imageScale} 
                                              imagePosition={el.imagePosition} 
                                              text={el.text}
                                              fontSize={el.fontSize} 
                                              fontFamily={el.fontFamily}
                                              textPosition={el.textPosition}
                                            />;
          case 'image_image':
                return <ImageImageComponent
                          {...commonProps} 
                          imageUrl1={el.imageUrl1}
                          imageScale1={el.imageScale1} 
                          imagePosition1={el.imagePosition1}
                          imageUrl2={el.imageUrl2}
                          imageScale2={el.imageScale2} 
                          imagePosition2={el.imagePosition2} 
                        />;
          case 'image_kpy':
            return <ImageKPYComponent
                      {...commonProps} 
                      imageUrl={el.imageUrl} imageScale={el.imageScale} imagePosition={el.imagePosition} 
                      text1={el.text1} fontSize1={el.fontSize1} fontFamily1={el.fontFamily1} textPosition1={el.textPosition1}
                      text2={el.text2} fontSize2={el.fontSize2} fontFamily2={el.fontFamily2} textPosition2={el.textPosition2}
                      text3={el.text3} fontSize3={el.fontSize3} fontFamily3={el.fontFamily3} textPosition3={el.textPosition3}
                      text4={el.text4} fontSize4={el.fontSize4} fontFamily4={el.fontFamily4} textPosition4={el.textPosition4}
                      text5={el.text5} fontSize5={el.fontSize5} fontFamily5={el.fontFamily5} textPosition5={el.textPosition5}
                      text6={el.text6} fontSize6={el.fontSize6} fontFamily6={el.fontFamily6} textPosition6={el.textPosition6}
                    />
          default:
            return null;
        }
      })}
    </group>
    </>
  );
};

export default SceneComponent;
