import { gsap } from "gsap";
import React, { useEffect } from 'react';
import { CopyShader } from "three/examples/jsm/Addons.js";

export const revealHero = () => {
  const tl = gsap.timeline();

  const publicElements = [
    "#publicLogo",
    "#publicTitle",
    "#publicDesc",
    "#publicButton1",
    "#publicButton2"
  ];

  const privateElements = [
    "#privateLogo",
    "#privateTitle",
    "#privateDesc",
    "#privateButton1",
    "#privateButton2"
  ];

  tl.delay(2);

  publicElements.forEach((element, index) => {
    tl.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "power3.inOut",
      },
      index * 0.2
    );
  });

  privateElements.forEach((element, index) => {
    tl.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "power3.inOut",
      },
      index * 0.2
    );
  });

  tl.fromTo(
    "#privateCommingSoon",
    {
      opacity: 0,
    },
    {
      opacity: 0.9,
      duration: 0.5,
      ease: "power3.inOut",
    },
  );

  return tl;
};

export const revealHeroPhone = () => {
  const tl = gsap.timeline();

  const phonePublicElements = [
    "#phoneLogo",
    "#phonePublicTitle",
    "#phonePublicDesc",
    "#phonePublicKit",
    "#phonePrivateTitle",
    "#phonePrivateDesc",
    "#phonePrivateKit"
  ];

  phonePublicElements.forEach((element, index) => {
    tl.fromTo(
      element,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "power3.inOut",
      },
      index * 0.2
    );
  });

  tl.fromTo(
    "#phoneIntroCall",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
      ease: "power3.inOut",
    },
  );

  return tl;
};