import { useEffect, useRef } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const CameraControls = () => {
  const { camera } = useThree();
  const mousePosition = useRef(new THREE.Vector2());
  const windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);
  const targetRotation = useRef(new THREE.Vector2());

  useEffect(() => {
    const onMouseMove = (event) => {
      mousePosition.current.x = (event.clientX - windowHalf.x) / 10000;
      mousePosition.current.y = (event.clientY - windowHalf.y) / 10000;
    };
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, []);

  useFrame(() => {
    const smooth = 0.025;

    targetRotation.current.x += (mousePosition.current.x - targetRotation.current.x) * smooth;
    targetRotation.current.y += (mousePosition.current.y - targetRotation.current.y) * smooth;

    camera.rotation.x = -targetRotation.current.y;
    camera.rotation.y = -targetRotation.current.x;
  });

  return null;
};

export default CameraControls;
