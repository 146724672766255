import React from 'react';
import ImageComponent from './ImageComponent';

const ImageImageComponent = ({ position, opacity, imageUrl1, imagePosition1, imageScale1, imageUrl2, imagePosition2, imageScale2 }) => {
  return (
    <group position={position}>
      <ImageComponent 
        position={imagePosition1}
        imageUrl={imageUrl1}
        scale={imageScale1}
        opacity={opacity}
      />
      <ImageComponent 
        position={imagePosition2}
        imageUrl={imageUrl2}
        scale={imageScale2}
        opacity={opacity}
      />
    </group>
  );
};

export default ImageImageComponent;
