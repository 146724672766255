// TextComponent.js
import React from 'react';
import { Text } from '@react-three/drei';

const TextComponent = ({ position, text, fontSize, fontFamily, opacity, textAlign="center", anchorX="center" }) => {
  const textPosition = Array.isArray(position) && position.length === 3 ? position : [0, 0, 0];

  return (
    <Text
      position={textPosition}
      fontSize={fontSize}
      color="#FFFFEE"
      font={fontFamily}
      anchorX={anchorX}
      anchorY="middle"
      textAlign={textAlign}
      transparent={true}
      lineHeight={1.5}
    >
      <meshStandardMaterial opacity={opacity}/>
      {text}
    </Text>
  );
};

export default TextComponent;
