import { useEffect, useRef, Suspense, useState } from "react";
import useMusic from '../GeneralJSEffects/MusicEffect';
import { gsap } from "gsap";
import { revealHero, revealHeroPhone } from "../GeneralAnimations/LandingPageAnimation";
import LoaderScreen from '../GeneralComponents/LoaderComponentLandingPage';
import { lazy } from '@loadable/component';


const LandingPage = () => {
  const small_screen = window.innerWidth < 680;
  const publicImageRef = useRef(null);
  const privateImageRef = useRef(null);

  const publicTextRef = useRef(null);
  const privateTextRef = useRef(null);

  const privateTextOverlayRef = useRef(null);

  const PublicHomeLoader = lazy(() => import('./PublicHome'));

  const handleEmailClick = (subject) => {
    handleCopyEmail();
    const body = `Name:%0D%0ABest contact Number:%0D%0ABrief Description of Works:`;
    window.location.href = `mailto:design@looksgenerous.com?subject=${encodeURIComponent(subject)}&body=${body}`;
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText("design@looksgenerous.com")
      .then(() => {
        alert('Email address copied to clipboard!\nSend us an email to get your Project Kit!');
        const buttons = document.querySelectorAll("#publicButton2, #privateButton2, #phonePublicKit, #phonePrivateKit");
        buttons.forEach(button => {
          button.textContent = "EMAIL COPIED";
          setTimeout(() => {
            button.textContent = "GET YOUR PROJECT KIT";
          }, 3500);
        });
      })
      .catch((error) => {
        console.error('Failed to copy email address:', error);
      });
  };

  useEffect(() => {
    if(!small_screen) { 
      gsap.set([publicImageRef.current, privateImageRef.current], { opacity: 0 }); 
      revealHero();
    } else {
      revealHeroPhone();
    }
  }, []);

  const handleMouseEnter = (side) => {
    if (side === 'public') {
      publicImageRef.current.style.zIndex = '100';
      gsap.to(publicImageRef.current, {
        opacity: 1,
        duration: 0.5,
        ease: 'power2.out'
      });
    } else if (side === 'private') {
      if (small_screen){
        gsap.to(privateTextOverlayRef.current, {
          opacity: 1,
          duration: 0.5,
          display: "block",
          ease: 'power2.out'
        });
      }
      else {
        privateImageRef.current.style.zIndex = '100';
        gsap.to(privateImageRef.current, {
          opacity: 1,
          duration: 0.5,
          ease: 'power2.out'
        });
      }
    }
  };

  const handleMouseLeave = (side) => {
    if (side === 'public') {
      publicImageRef.current.style.zIndex = '0';
      gsap.to(publicImageRef.current, {
        opacity: 0,
        duration: 0.5,
        ease: 'power2.out',
      });
    } else if (side === 'private') {
      if (small_screen){
        gsap.to(privateTextOverlayRef.current, {
          opacity: 0,
          duration: 0.5,
          display: "none",
          ease: 'power2.out'
        });
      } else {
        privateImageRef.current.style.zIndex = '0';
        gsap.to(privateImageRef.current, {
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out'
        });
      }
    }
  };

  const handleGeneralClick = () => {
    if(privateTextOverlayRef.current.style.display == 'block') {
      gsap.to(privateTextOverlayRef.current, {
        opacity: 0,
        duration: 0.5,
        display: "none",
        ease: 'power2.out'
      });
    }
  };

  const handleClick = () => {
    if(privateTextOverlayRef.current.style.display == 'none') {
      gsap.to(privateTextOverlayRef.current, {
        opacity: 1,
        duration: 0.5,
        display: "block",
        ease: 'power2.out'
      });
    }
  };

  return (
    <>
      {small_screen ? (
      <>
      <LoaderScreen />
      <div className="flex flex-col" style={{ height: "100%" }} onClick={() => handleGeneralClick()}>
        <div className="absolute top-0 left-0 right-0 text-center text-[#F6F6EE] py-4 z-10 pt-[calc(env(safe-area-inset-top)+20px)]">
          <p className="text-[20px] font-founderGroteskBold" id="phoneLogo">LOOKS GENEROUS</p>
        </div>
        <div className="flex-1 relative">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-cover bg-top z-0"
            style={{ backgroundImage: 'url("/assets/landingpage/public.jpeg")' }}
          ></div>
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-60 z-0"></div>
          <div className="flex flex-col items-center justify-center h-full relative z-10">
            <div className="text-center text-[#F6F6EE]">
              <a href="/public" className="pb-[0px]">
                <h2 ref={publicTextRef} className="text-[60px] font-selfModern cursor-pointer text-hover h-[75px]" id="phonePublicTitle" style={{fontWeight: "normal"}}>
                  PUBLIC
                </h2>
              </a>
              <p className="text-[18px] font-founderGrotesk mt-2" id="phonePublicDesc">HOTEL, BAR + RESTAURANT DESIGN</p>
              <p className="text-[16px] font-founderGroteskBold mt-4" onClick={() => handleEmailClick('New Project')} id="phonePublicKit">REQUEST PUBLIC PROJECT KIT</p>
            </div>
          </div>
        </div>
        <div className="flex-1 relative">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-cover bg-top z-0"
            style={{ backgroundImage: 'url("/assets/landingpage/private.jpeg")' }}
          ></div>
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-60 z-0"></div>
          <div className="flex flex-col items-center justify-center h-full relative z-10">
            <div className="text-center text-[#F6F6EE]">
              <a className="pb-[0px]">
                <h2 ref={privateTextRef} className="text-[60px] font-selfModern cursor-pointer text-hover h-[75px]" 
                  onClick={() => handleClick()} id="phonePrivateTitle">
                    PRIVATE
                </h2>
              </a>
              <p className="text-[18px] font-founderGrotesk mt-2" id="phonePrivateDesc">HOME INTERIOR DESIGN + FURNISHING</p>
              <p className="text-[16px] font-founderGroteskBold mt-[22px]" onClick={() => handleEmailClick('New Project')} id="phonePrivateKit">REQUEST PRIVATE PROJECT KIT</p>
            </div>
            <span ref={privateTextOverlayRef} className="absolute bottom-[45px] bg-[#FFFF6A] text-[#21140E] z-0 opacity-0 mx-[10px] text-[14px] font-founderGrotesk px-[30px] pb-[20px] pt-[30px]" style={{display: "none"}}>
              PRIVATE HOSPITALITY SITE COMING SOON!
              <br />
              <br />
              PLEASE GET IN TOUCH BELOW VIA AN INTRO CALL WITH
              <br />
              OUR DIRECTOR GEORGINA.
              <br />
              <br />
              OR, REQUEST TO SEE OUR PRICING, PACKAGE OPTIONS,
              <br />
              PROCESS AND MORE VIA OUR PROJECT KIT.
            </span>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 text-center text-[#F6F6EE] py-4 z-10 pb-[calc(env(safe-area-inset-bottom) + 20px)]">
          <a href="https://www.picktime.com/dd59b938-4bd8-451c-88e7-c0f5d6b49668" target="_blank" className="p-[0px]">
            <p className="text-[16px] font-founderGroteskBold border border-[#FFF] rounded-[5px] inline-block px-4 pb-[1px] pt-[7px]" id="phoneIntroCall"><strong>BOOK AN INTRO CALL</strong></p>
          </a>
        </div>
      </div>
      </>
      ) : (
        <>
        <LoaderScreen />
        <div className="flex h-screen">
          <div
            className="w-1/2 bg-[#21140E] flex flex-col items-center justify-center relative"
            onMouseEnter={() => handleMouseEnter('public')}
            onMouseLeave={() => handleMouseLeave('public')}
          >
            <div className="text-center text-[#F6F6EE] z-10">
              <p className="text-[2vw] mb-[9rem] mt-[1rem] font-founderGroteskBold" id="publicLogo">LOOKS GENEROUS</p>
              <a href="/public" className="pb-[0px]">
                <h2 ref={publicTextRef} className="text-[8vw] font-selfModern cursor-pointer text-hover" id="publicTitle" style={{lineHeight: "1.2"}}>
                  PUBLIC
                </h2>
              </a>
              <p className="text-[1.5vw] font-founderGrotesk mb-[7rem]" id="publicDesc">HOTEL, BAR + RESTAURANT DESIGN</p>
              <div className="space-y-4">
                <a href="https://www.picktime.com/dd59b938-4bd8-451c-88e7-c0f5d6b49668" target="_blank" rel="noopener noreferrer">
                  <button className="text-white text-[1vw] block mx-auto font-founderGroteskBold text-hover-buttons landing_page_button" id="publicButton1">BOOK AN INTRO CALL</button>
                </a>
                <button
                  className="text-white text-[1vw] block mx-auto font-founderGroteskBold text-hover-buttons landing_page_button"
                  onClick={() => handleEmailClick('New Project')}
                  id="publicButton2"
                >
                  GET YOUR PROJECT KIT
                </button>
              </div>
            </div>
            <div ref={privateImageRef} className="absolute inset-0 flex items-center justify-center z-0">
              <img src="/assets/landingpage/private.jpeg" alt="Private" className="w-full h-full object-cover object-top" />
            </div>
          </div>
          <div className="w-1/2 bg-[#F6F6EE] flex flex-col items-center justify-center relative"
            onMouseEnter={() => handleMouseEnter('private')}
            onMouseLeave={() => handleMouseLeave('private')}
          >
            <div className="text-center text-[#21140E] z-10">
              <p className="text-[2vw] mb-[9rem] mt-[1rem] font-founderGroteskBold" id="privateLogo">LOOKS GENEROUS</p>
              <div className="relative flex items-center justify-center">
                <h2 ref={privateTextRef} className="text-[8vw] font-selfModern cursor-pointer text-hover" id="privateTitle" style={{lineHeight: "1.2"}}>PRIVATE</h2>
                <div className="absolute">
                  <p className="text-[0.8em] lg:text-[1.5em] sm:text-[1.2em] pb-[7px] pt-[17px] pr-[2em] pl-[2em] lg:pr-[4em] lg:pl-[4em] font-founderGroteskBold" style={{backgroundColor: "#FFFF78", rotate: "-13deg", opacity: "0.9", cursor: "pointer", whiteSpace: "nowrap"}} id="privateCommingSoon">PRIVATE SITE COMING SOON</p>
                </div>
              </div>
              <p className="text-[1.5vw] font-founderGrotesk mb-[7rem]" id="privateDesc">HOME INTERIOR DESIGN + FURNISHING</p>
              <div className="space-y-4">
                <a href="https://www.picktime.com/dd59b938-4bd8-451c-88e7-c0f5d6b49668" target="_blank" rel="noopener noreferrer">
                  <button className="text-[#21140E] text-[1vw] block mx-auto font-founderGroteskBold text-hover-buttons landing_page_button" id="privateButton1">BOOK AN INTRO CALL</button>
                </a>
                <button
                  className="text-[#21140E] text-[1vw] block mx-auto font-founderGroteskBold text-hover-buttons landing_page_button"
                  onClick={() => handleEmailClick('New Project')}
                  id="privateButton2"
                >
                  GET YOUR PROJECT KIT
                </button>
              </div>
            </div>
            <div ref={publicImageRef} className="absolute inset-0 flex items-center justify-center z-0">
              <img src="/assets/landingpage/public.jpeg" alt="Public" className="w-full h-full object-cover object-top" />
            </div>
          </div>
        </div>
        <Suspense fallback={null}>
          <PublicHomeLoader />
        </Suspense>
        </>
      )}
    </>
  );
};

export default LandingPage;