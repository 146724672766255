import React from 'react';
import ImageComponent from './ImageComponent';
import TextComponent from './TextComponent';

const ImageTextComponent = ({ position, imageUrl, text, imagePosition, textPosition, imageScale, opacity, fontSize, fontFamily }) => {
  return (
    <group position={position}>
      <ImageComponent 
        position={imagePosition}
        imageUrl={imageUrl}
        scale={imageScale}
        opacity={opacity}
      />
      <TextComponent 
        position={textPosition}
        text={text}
        fontSize={fontSize}
        fontFamily={fontFamily}
        opacity={opacity}
        textAlign='left'
      />
    </group>
  );
};

export default ImageTextComponent;
