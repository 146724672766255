import gsap from 'gsap';

export const useLoaderAnimationLandingPage = (duration) => {
    const startAnimation = () => {
        gsap.delayedCall(duration, () => {
            gsap.to("#loader", {
                duration: 0.5,
                opacity: 0,
            }).then(() => {
                gsap.to("#loader", {
                    duration: 0.2,
                    y: window.innerHeight,
                })
            });
        });
    };

    return { startAnimation };
};

export const useLoaderAnimation = (duration) => {
    const startAnimation = () => {
        gsap.delayedCall(duration, () => {
            gsap.to("#loader", {
                duration: 0.5,
                opacity: 0,
            }).then(() => {
                gsap.to("#mouse-circle", {
                    display: "block",
                    opacity: 1
                });
                gsap.to("#loader", {
                    duration: 0.2,
                    y: window.innerHeight,
                });
                gsap.to("#loader_text_enter", {
                    duration: 1,
                    opacity: 1,
                });
            });
        });
    };

    return { startAnimation };
};


