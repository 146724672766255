import React, { useEffect } from 'react';
import { useLoaderAnimation } from '../GeneralAnimations/LoadingAnimation';

const LoaderScreen = ({ text }) => {
  const { startAnimation } = useLoaderAnimation(5);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);

  return (
    <>
      <div style={{ marginTop: '35px', opacity: 1 }} id="loader_text_enter">
        LOOKS GENEROUS
      </div>
      <div className="loader" id="loader">
        <div data-content="FEEL SOUND LOOK" className="logo_cointainer">
          <img src="/assets/LG_logo.png" alt="Logo" className="logo_loader" />
          <img src="/assets/LG_logo.png" alt="Logo" className="logo_loader_to_fill" />
        </div>
        <div className="loader_text_desc" style={{ marginTop: '70px' }}>
          {text}
        </div>
        <div className="loader_text_bottom">Melbourne, Australia</div>
      </div>
    </>
  );
};

export default LoaderScreen;