import React from 'react';
import ImageComponent from './ImageComponent';
import TextComponent from './TextComponent';

const ImageKPYComponent = ({  position, opacity,
                              imageUrl, imagePosition, imageScale, 
                              text1, textPosition1, fontSize1, fontFamily1,
                              text2, textPosition2, fontSize2, fontFamily2,
                              text3, textPosition3, fontSize3, fontFamily3,
                              text4, textPosition4, fontSize4, fontFamily4,
                              text5, textPosition5, fontSize5, fontFamily5,
                              text6, textPosition6, fontSize6, fontFamily6
                            }) => {
  return (
    <group position={position}>
      <ImageComponent 
        position={imagePosition}
        imageUrl={imageUrl}
        scale={imageScale}
        opacity={opacity}
      />
      <TextComponent 
        position={textPosition1}
        text={text1}
        fontSize={fontSize1}
        fontFamily={fontFamily1}
        opacity={opacity}
        textAlign='center'
      />
      <TextComponent 
        position={textPosition2}
        text={text2}
        fontSize={fontSize2}
        fontFamily={fontFamily2}
        opacity={opacity}
        textAlign='center'
      />
      <TextComponent 
        position={textPosition3}
        text={text3}
        fontSize={fontSize3}
        fontFamily={fontFamily3}
        opacity={opacity}
        textAlign='center'
      />
      <TextComponent 
        position={textPosition4}
        text={text4}
        fontSize={fontSize4}
        fontFamily={fontFamily4}
        opacity={opacity}
        textAlign='center'
      />
      <TextComponent 
        position={textPosition5}
        text={text5}
        fontSize={fontSize5}
        fontFamily={fontFamily5}
        opacity={opacity}
        textAlign='center'
      />
      <TextComponent 
        position={textPosition6}
        text={text6}
        fontSize={fontSize6}
        fontFamily={fontFamily6}
        opacity={opacity}
        textAlign='center'
      />
    </group>
  );
};

export default ImageKPYComponent;
