import { useState, useEffect } from 'react';

const useMusic = (musicUrl) => {
  const [audioContext, setAudioContext] = useState(null);
  const [source, setSource] = useState(null);
  const [gainNode, setGainNode] = useState(null);
  const [isMusicPlaying, setIsMusicPlaying] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [pauseTime, setPauseTime] = useState(0);

  useEffect(() => {
    const createAudioContext = () => {
      let newAudioContext;
      try {
        if (typeof AudioContext !== 'undefined') {
          newAudioContext = new AudioContext();
        } else if (typeof webkitAudioContext !== 'undefined') {
          newAudioContext = new window.webkitAudioContext();
        } else {
          throw new Error('Web Audio API not supported');
        }
        return newAudioContext;
      } catch (e) {
        console.error('Web Audio API is not supported in this browser');
      }
    };

    const newAudioContext = createAudioContext();
    setAudioContext(newAudioContext);

    if (newAudioContext) {
      const newGainNode = newAudioContext.createGain();
      newGainNode.connect(newAudioContext.destination);
      setGainNode(newGainNode);
    }
  }, []);

  const loadMusic = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    setAudioBuffer(audioBuffer);
  };

  const playMusic = () => {
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }
    let newSource = audioContext.createBufferSource();
    newSource.buffer = audioBuffer;
    newSource.loop = true;
    newSource.connect(gainNode);
    gainNode.gain.setValueAtTime(0, audioContext.currentTime);
    gainNode.gain.linearRampToValueAtTime(1, audioContext.currentTime + 1);
    newSource.start(0, pauseTime % audioBuffer.duration);
    setSource(newSource);
    setIsMusicPlaying(true);
  };

  const stopMusic = () => {
    if (!source) return;
    const currentTime = audioContext.currentTime;
    gainNode.gain.setValueAtTime(gainNode.gain.value, currentTime);
    gainNode.gain.linearRampToValueAtTime(0, currentTime + 1);
    source.stop(currentTime + 1);
    setPauseTime((prevPauseTime) => prevPauseTime + currentTime);
    setSource(null);
    setIsMusicPlaying(false);
  };

  const toggleMusic = async () => {
    if (isMusicPlaying) {
      stopMusic();
    } else {
      if (!audioBuffer) {
        await loadMusic(musicUrl);
      }
      playMusic();
    }
  };

  useEffect(() => {
    const resumeAudioContext = () => {
      if (audioContext?.state === 'suspended') {
        audioContext.resume();
      }
    };

    document.body.addEventListener('touchend', resumeAudioContext);

    return () => {
      document.body.removeEventListener('touchend', resumeAudioContext);
    };
  }, [audioContext]);

  useEffect(() => {
    if (audioContext && musicUrl) {
      loadMusic(musicUrl);
    }
  }, [audioContext, musicUrl]);

  return { playMusic, stopMusic, toggleMusic, isMusicPlaying };
};

export default useMusic;
