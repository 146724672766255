import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './GeneralPages/LandingPage';
import PublicHome from './GeneralPages/PublicHome';
import PrivateHome from './GeneralPages/PrivateHome';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/public" element={<PublicHome />} />
        <Route path="/private" element={<PrivateHome />} />
      </Routes>
    </Router>
  );
};

export default App;