import React from 'react';
import { Plane } from '@react-three/drei';
import { useVideoTexture } from '@react-three/drei';

const VideoComponent = ({ position, videoUrl, scale, opacity }) => {
  const videoTexture = useVideoTexture(videoUrl);

  return (
    <Plane args={[1, 1]} position={position} scale={scale}>
      <meshBasicMaterial
        attach="material"
        map={videoTexture}
        toneMapped={false}
        transparent={true}
        opacity={opacity}
      />
    </Plane>
  );
};

export default VideoComponent;
