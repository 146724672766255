import React, { useEffect } from 'react';
import gsap from 'gsap';

export const useEnterAnimationLandingPage = () => {
  useEffect(() => {
    const enterButton = document.getElementById('enter_button');
    const enterWithoutMusicButton = document.getElementById('enter_button_without_music');
    const toggleMusicOn = document.getElementById('toggleMusicOn');
    const toggleMusicOff = document.getElementById('toggleMusicOff');
    const enterPage = document.getElementById('enter_page_id');
    const bottomMenu = document.getElementById('bottom_menu');

    const enter = async () => {
      toggleMusicOn.style.display = "none";
      toggleMusicOff.style.display = "inline";
      gsap.to("#loader_text_enter", {
        opacity: 0,
        ease: "power4.out"
      });
      gsap.to(enterPage, {
        opacity: 0,
        ease: "power4.out"
      }).then(() => {
        gsap.set(enterPage, { display: "none" })
      });
    };

    const enterWithoutMusic = async () => {
      toggleMusicOn.style.display = "inline";
      toggleMusicOff.style.display = "none";
      gsap.to("#loader_text_enter", {
          opacity: 0,
          ease: "power4.out"
      });
      gsap.to(enterPage, {
        opacity: 0,
        ease: "power4.out"
      }).then(() => {
        gsap.set(enterPage, { display: "none" })
      });
    };

    if (enterButton) enterButton.addEventListener('click', enter);
    if (enterWithoutMusicButton) enterWithoutMusicButton.addEventListener('click', enterWithoutMusic);

    // Cleanup function
    return () => {
      if (enterButton) enterButton.removeEventListener('click', enter);
      if (enterWithoutMusicButton) enterWithoutMusicButton.removeEventListener('click', enterWithoutMusic);
    };
  }, []);

  return null; // Since we are manipulating elements outside React, this component doesn't render anything.
};

export const useEnterAnimation = () => {
  useEffect(() => {
    const enterButton = document.getElementById('enter_button');
    const enterWithoutMusicButton = document.getElementById('enter_button_without_music');
    const toggleMusicOn = document.getElementById('toggleMusicOn');
    const toggleMusicOff = document.getElementById('toggleMusicOff');
    const enterPage = document.getElementById('enter_page_id');
    const bottomMenu = document.getElementById('bottom_menu');

    const enter = async () => {
      toggleMusicOn.style.display = "none";
      toggleMusicOff.style.display = "inline";
      gsap.to("#loader_text_enter", {
        y: () => -(document.querySelector("#root").getBoundingClientRect().height/2) - 20,
        duration: 2.2,
        ease: "power4.out"
      });
      gsap.to(enterPage, {
        opacity: 0
      }).then(() => {
        gsap.set(enterPage, { display: "none" })
        gsap.to(bottomMenu, {
          duration: 1,
          opacity: 1,
        });
      });
    };

    const enterWithoutMusic = async () => {
      gsap.to("#toggleMusic", {
          duration: 1,
          y: 0,
          opacity: 1,
      });
      toggleMusicOn.style.display = "none";
      toggleMusicOff.style.display = "inline";
      gsap.to("#loader_text_enter", {
          y: () => -(document.querySelector("#root").getBoundingClientRect().height/2) - 20,
          duration: 2.2,
          ease: "power4.out"
      });
      gsap.to(enterPage, {
        opacity: 0
      }).then(() => {
        gsap.set(enterPage, { display: "none" })
        gsap.to(bottomMenu, {
          duration: 1,
          opacity: 1,
        });
      });
    };

    if (enterButton) enterButton.addEventListener('click', enter);
    if (enterWithoutMusicButton) enterWithoutMusicButton.addEventListener('click', enterWithoutMusic);

    // Cleanup function
    return () => {
      if (enterButton) enterButton.removeEventListener('click', enter);
      if (enterWithoutMusicButton) enterWithoutMusicButton.removeEventListener('click', enterWithoutMusic);
    };
  }, []);

  return null; // Since we are manipulating elements outside React, this component doesn't render anything.
};
