import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';

const GradientCustomMobile = () => {
  const meshRef = useRef();

  const { geometry, material } = useMemo(() => {
    // Reduced complexity of the plane geometry
    const geometry = new THREE.PlaneGeometry(320, 320, 1, 1);
    const material = new THREE.MeshBasicMaterial({
      color: 0x2e1c0f,
      side: THREE.DoubleSide
    });

    return { geometry, material };
  }, []);

  React.useEffect(() => {
    return () => {
      geometry.dispose();
      material.dispose();
    };
  }, [geometry, material]);

  return (
    <mesh
      ref={meshRef}
      geometry={geometry}
      material={material}
      position={[0, 0, -35]}
    />
  );
};

export default GradientCustomMobile;
