import React, { useMemo } from 'react';
import TextComponent from './TextComponent';
import { Plane } from '@react-three/drei';

const GridComponent = ({ items, position, opacity }) => {
    const horizontalSpacing = 2.0;
    const verticalSpacing = 1;
    const estimatedTextWidth = 4;

    const centerOffset = useMemo(() => {
        const totalHeight = (items.length - 1) * verticalSpacing;
        const totalWidth = estimatedTextWidth + horizontalSpacing;

        return {
            x: -totalWidth / 2,
            y: totalHeight / 2,
            z: 0
        };
    }, [items.length, horizontalSpacing, verticalSpacing, estimatedTextWidth]);

    const fixedText = "*Project Design Lead At European Firm";

    const fixedTextPosition = useMemo(() => {
        const yPosition = position[1] + centerOffset.y - (items.length * verticalSpacing);
        return [-3.5, yPosition+0.2, position[2]];
    }, [position, centerOffset.y, items.length, verticalSpacing]);

    const gridItems = items.map((item, index) => {
        const xPosition = position[0] + centerOffset.x;
        const yPosition = position[1] + centerOffset.y - (index * verticalSpacing);
        const zPosition = position[2];
        return (
            <group key={index} position={[xPosition, yPosition, zPosition]}>
                <Plane
                    args={[8.7, 0.02]}
                    position={[3.3, 0.5, 0]}
                >
                    <meshBasicMaterial 
                        attach="material" 
                        color="white"
                        opacity={opacity}
                        transparent={true}
                    />
                </Plane>
                <TextComponent
                    position={[-0.5, 0, 0]}
                    text={(index+1 >= 10 ? (index+1) : "0" + (index+1))}
                    fontSize={0.25}
                    fontFamily={item.fontFamily}
                    opacity={opacity}
                    textAlign={"left"}
                    anchorX={"left"}
                />
                <TextComponent
                    position={[0, 0, 0]}
                    text={item.title}
                    fontSize={item.fontSize}
                    fontFamily={item.fontFamily}
                    opacity={opacity}
                    textAlign={"left"}
                    anchorX={"left"}
                />
                <TextComponent
                    position={[horizontalSpacing*2, 0, 0]}
                    text={item.description}
                    fontSize={0.25}
                    fontFamily={item.fontFamily}
                    opacity={opacity}
                    textAlign={"left"}
                    anchorX={"left"}
                />
                <Plane
                    args={[8.7, 0.02]}
                    position={[3.3, -0.5, 0]}
                >
                    <meshBasicMaterial 
                        attach="material" 
                        color="white"
                        opacity={opacity}
                        transparent={true}
                    />
                </Plane>
            </group>
        );
    });

    return <>
        {gridItems}
        <TextComponent
                position={fixedTextPosition}
                text={fixedText}
                fontSize={0.22}
                fontFamily={"./fonts/suisse_int_2.ttf"}
                opacity={opacity}
                textAlign={"left"}
                anchorX={"left"}
            />
        </>;
};

export default GridComponent;
