import React, { useEffect, useRef, useState } from 'react';
import { useLoaderAnimation } from '../GeneralAnimations/LoadingAnimation';
import { useEnterAnimation } from '../GeneralAnimations/EnterAnimation';
import useMusic from '../GeneralJSEffects/MusicEffect';
import GradientCustom from '../GradientCustom/GradientCustom';
import SceneComponent from '../GeneralScene/SceneComponent';
import CameraControls from '../GeneralAnimations/CameraAnimation';
import gsap from 'gsap';
import EnterScreen from '../GeneralComponents/EnterComponent';
import { useLocation } from 'react-router-dom';

import { Canvas } from '@react-three/fiber';
import ContactPage from './ContactPage';
import GradientCustomMobile from '../GradientCustom/GradientCustomMobile';
import LoaderScreen from '../GeneralComponents/LoaderComponent';

const PublicHome = () => {
  const { startAnimation } = useLoaderAnimation(5);
  const { isMusicPlaying, toggleMusic } = useMusic("/lg_music.mp3");
  const small_screen = window.innerWidth < 480;
  const location = useLocation();
  const isPublicRoute = location.pathname === '/public';

  const animationStarted = useRef(false);
  useEnterAnimation();

  //- ENTER ANIMATION AND ENTER MUSIC BUTTON
  useEffect(() => {
    if (!animationStarted.current) {
      startAnimation();
      animationStarted.current = true;
    }
    const enterButton = document.getElementById('enter_button');
    if (enterButton) enterButton.addEventListener('click', toggleMusic);

    return () => {
      if (enterButton) enterButton.removeEventListener('click', toggleMusic);
    };
  }, [startAnimation, toggleMusic]);

  //- SOUNDS ON / OFF MUSIC BUTTON
  useEffect(() => {
    const toggleButton = document.getElementById('toggleMusic');
    const musicOn = document.getElementById('toggleMusicOn');
    const musicOff = document.getElementById('toggleMusicOff');
    const handleToggleClick = () => {
      toggleMusic();
    };
    if (toggleButton) toggleButton.addEventListener('click', handleToggleClick);
    return () => {
      if (toggleButton) toggleButton.removeEventListener('click', handleToggleClick);
    };
  }, [toggleMusic, isMusicPlaying]);

  //- FOLLOW MOUSE
  useEffect(() => {
    if(!small_screen){
      const mouseCircle = document.getElementById('mouse-circle');
      let lastX = 0;
      let lastY = 0;
    
      const moveCircle = (e) => {
        const mouseX = e.clientX;
        const mouseY = e.clientY;
        const dx = mouseX - lastX;
        const dy = mouseY - lastY;
        const velocity = Math.sqrt(dx * dx + dy * dy);
    
        // Ajustez ces valeurs selon vos besoins
        const scale = Math.max(0.5, Math.min(1.5, 1 - velocity / 30));
    
        gsap.to(mouseCircle, {
          left: mouseX,
          top: mouseY,
          scale: scale, // Ajuste l'échelle uniformément sur les axes X et Y
          duration: 0.35,
          ease: 'power1.out'
        });
    
        lastX = mouseX;
        lastY = mouseY;
      };
    
      window.addEventListener('mousemove', moveCircle);
    
      return () => {
        window.removeEventListener('mousemove', moveCircle);
      };
    }
  }, []);
  
  return (
    <>
      {isPublicRoute && (
        <>
          <LoaderScreen text={"PUBLIC HOSPITALITY"} />
          <EnterScreen />
        </>
      )}
      <Canvas>
        <ambientLight intensity={20}/>
        <pointLight position={[10, 10, 10]} />

        {!small_screen && <CameraControls />}
        {!small_screen && <GradientCustom />}
        {small_screen && <GradientCustomMobile />}
        <SceneComponent />
        
      </Canvas>
      <ContactPage />
    </>
  );
}

export default PublicHome;