import React, { useEffect } from 'react';
import { useLoaderAnimationLandingPage } from '../GeneralAnimations/LoadingAnimation';

const LoaderScreen = () => {
  const { startAnimation } = useLoaderAnimationLandingPage(2.2);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);

  return (
    <div className="loader_landingpage" id="loader">
      <div className="logo_container_landingpage">
        <img src="/assets/LG_logo.png" alt="Logo" className="logo_loader_landingpage" />
        <img src="/assets/LG_logo.png" alt="Logo" className="logo_loader_to_fill_landingpage" />
      </div>
    </div>
  );
};

export default LoaderScreen;