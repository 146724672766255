import React, { useRef, useEffect, useLayoutEffect } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import useContactAnimation from '../GeneralAnimations/ContactAnimation';
import { useFrame } from '@react-three/fiber';

const ContactPage = () => {
  useContactAnimation();
  const scrollRef = useRef(null);
  let scroll = null;
  let small_screen = window.innerWidth < 1000

  useEffect(() => {
    scroll = scrollRef.current
      ? new LocomotiveScroll({
          el: scrollRef.current,
          smooth: true,
          smoothMobile: true,
          smoothTablet: true,
          lerp: 0.03,
          smoothSpeed: 0.3,
          inertia: 0.7,
          mobile: {
            inertia: 0,
            smooth: true,
            breakpoint: 0,
          },
          tablet: {
              inertia: 0,
              smooth: true,
              breakpoint: 0,
          }
        })
      : null;

    const interval = setInterval(() => {
      if (scroll) {
        scroll.update();
      }
    }, 1000);

    return () => {
      if (scroll){
        scroll.destroy()
      }
      clearInterval(interval);
    };
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (scroll) {
        scroll.update();
      }
    };
    window.addEventListener('resize', handleResize);
    if (scroll) {
      scroll.update();
    }
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
    {small_screen ? (
      <div className="main_contact" id="main_contact_id" ref={scrollRef} data-scroll-container>
    <span className="img_cross" id="close_contact">
      <img alt="cross" src="/assets/contact/cross.svg"/>
    </span>

    <div className="contact_wts" data-scroll-section>
      <div className="contact_grid_1" data-scroll>
        <h1 className="h1_contact">Contact</h1>
        <div className="contact_section_rebrand" style={{paddingTop: "30px"}}>
          <h1 style={{fontSize: "30px"}}>NEW BUSINESS</h1>
          <p>For new project enquiries, request our Project Kit or to just have coffee, please get in touch —</p>
          <p style={{textDecoration: "underline"}}><a href='mailto:design@looksgenerous.com' style={{textDecoration: "none", color: "#FFFFEE", padding: "0px"}}>design@looksgenerous.com</a></p>
        </div>
        <br />
        <div className="contact_section_rebrand" >
          <h1 style={{fontSize: "30px"}}>OFFICE</h1>
          <div style={{display: "inline"}}>
            <p style={{fontSize: 14}}>(By Appointment Only)</p>
            <p>36 Grove Road, Hawthorn</p>
            <p>Victoria, Australia 3122</p>
          </div>
        </div>
        <br />
        <div className="contact_section_rebrand">
          <h1 style={{fontSize: "30px"}}>SOCIAL</h1>
          <div>
            <p style={{display: "inline"}}><a href="https://www.instagram.com/looksgenerous/" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>Instagram, </a></p>
            <p style={{display: "inline"}}><a href="https://www.linkedin.com/company/looks-generous-creative-consultancy/about/" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>Facebook, </a></p>
            <p style={{display: "inline"}}><a href="https://www.facebook.com/looksgenerous" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>LinkedIn</a></p>
          </div>
        </div>
        <br />
        <div className="contact_section_rebrand">
          <h1 style={{fontSize: "30px"}}>CAREERS</h1>
          <p style={{paddingBottom: "25px"}}>Know the power of stories through design? Think out of the box? If you're an ArchiCAD wizard with a desire to utilise the latest technology, please send through your CV and Folio (max 15mb).</p>
        </div>
      </div>
      <div className="contact_grid_2" data-scroll>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}>
          <img className="img_contact" src="/assets/contact/contact_01.png" alt="Contact" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="contact_grid_3" data-scroll>
        <h1 className="h1_contact" style={{paddingTop: "25px"}}>Team</h1>
        <div className="contact_section_rebrand" style={{paddingTop: "30px"}}>
          <h1 style={{margin: "0px", fontSize: "30px"}}>GEORGIE PRITTIE</h1>
          <p style={{fontSize: "12px"}} className='titles_team'>DIRECTOR, INTERIOR ARCHITECT</p>
          <p style={{fontSize: "12px"}}>(M. ARCH. HONS)</p>
          <br />
          <p>Georgina's upbringing in a family of interior designers and furniture dealers instilled in her a lifelong passion for timeless and sensory experiences. After nearly a decade in Copenhagen leading projects across Europe and Asia, her unique approach has evolved to blend old and new styles, weaving her love of texture and tactility to craft dream like environments.</p>
        </div>
        <br />
        <br />
        <div className="contact_section_rebrand">
          <h1 style={{margin: "0px", fontSize: "30px"}}>CJ WRIGHT</h1>
          <p style={{fontSize: "12px"}} className='titles_team'>FOUNDER, DIRECTOR, INTERIOR ARCHITECT</p>
          <p style={{fontSize: "12px"}} className='titles_team'>(B. ARCH. + CONS. MGMT(HONS))</p>
          <br />
          <p>The technical and story-telling-brand-guy. Ten years speaking fluent hospo. Drives design from the bottom line up. Will chew your ear off about the buyer influencing power of good playlists. Designed 2 world's top 50 cocktail bars but really more of a wine guy.</p>
        </div>
      </div>
    </div>

    <div className="contact_footer" data-scroll-section>
      <p>© LOOKS GENEROUS PTY. LTD. 2023</p>
    </div>
  </div>
    ) : 
    (
    <>
    <div className="main_contact" id="main_contact_id" ref={scrollRef} data-scroll-container>
      <span className="img_cross" id="close_contact">
        <img alt="cross" src="/assets/contact/cross.svg"/>
      </span>
      {/* <span className="img_down">
        <img alt="down-arrow" src="./assets/contact/down-arrow.svg" className="bounce" />
      </span> */}
      
      <div className="contact_wts" data-scroll-section>
        <div className="contact_grid_1" data-scroll data-scroll-speed={small_screen ? "1" : "4"} data-scroll-direction="horizontal" data-scroll-position="top" style={{paddingRight:"35px"}}>
          <h1 className="h1_contact">Contact</h1>
          <div className="contact_section_rebrand" style={{paddingTop: "30px"}}>
            <h1 style={{fontSize: "30px"}}>NEW BUSINESS</h1>
            <p>For new project enquiries, request our Project Kit or to just have coffee, please get in touch —</p>
            <p style={{textDecoration: "underline"}}><a href='mailto:design@looksgenerous.com' style={{textDecoration: "none", color: "#FFFFEE", padding: "0px"}}>design@looksgenerous.com</a></p>
          </div>
          <br />
          <div className="contact_section_rebrand" >
            <h1 style={{fontSize: "30px"}}>OFFICE</h1>
            <div style={{display: "inline"}}>
            <p style={{fontSize: 14}}>(By Appointment Only)</p>
            <p>36 Grove Road, Hawthorn</p>
            <p>Victoria, Australia 3122</p>
            </div>
          </div>
          <br />
          <div className="contact_section_rebrand">
            <h1 style={{fontSize: "30px"}}>SOCIAL</h1>
            <div>
              <p style={{display: "inline"}}><a href="https://www.instagram.com/looksgenerous/" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>Instagram, </a></p>
              <p style={{display: "inline"}}><a href="https://www.linkedin.com/company/looks-generous-creative-consultancy/about/" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>Facebook, </a></p>
              <p style={{display: "inline"}}><a href="https://www.facebook.com/looksgenerous" target='_blank' style={{textDecoration: "none", display: "inline", padding:"0px", color:"#ffffee"}}>LinkedIn</a></p>
            </div>
          </div>
          <br />
          <div className="contact_section_rebrand">
            <h1 style={{fontSize: "30px"}}>CAREERS</h1>
            <p>Know the power of stories through design? Think out of the box? If you're an ArchiCAD wizard with a desire to utilise the latest technology, please send through your CV and Folio (max 15mb).</p>
          </div>
        </div>
        <div className="contact_grid_2" data-scroll data-scroll-speed={small_screen ? "1" : "4"} >
          <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}>
            <img className="img_contact" src="/assets/contact/contact_01.png" alt="Contact" />
          </div>
        </div>
        <div className="contact_grid_3" data-scroll data-scroll-speed= {small_screen ? "1": "-4"} data-scroll-direction="horizontal" data-scroll-position={small_screen ? "center" : "top"} style={{paddingLeft:"35px"}}>
          <h1 className="h1_contact">Team</h1>
          <div className="contact_section_rebrand" style={{paddingTop: "30px"}}>
            <h1 style={{margin: "0px", fontSize: "30px"}}>GEORGIE PRITTIE</h1>
            <p style={{fontSize: "12px"}} className='titles_team'>DIRECTOR, INTERIOR ARCHITECT</p>
            <p style={{fontSize: "12px"}}>(M. ARCH. HONS)</p>
            <br />
            <p>Georgina's upbringing in a family of interior designers and furniture dealers instilled in her a lifelong passion for timeless and sensory experiences. After nearly a decade in Copenhagen leading projects across Europe and Asia, her unique approach has evolved to blend old and new styles, weaving her love of texture and tactility to craft dream like environments.</p>
          </div>
          <br />
          <br />
          <div className="contact_section_rebrand">
            <h1 style={{margin: "0px", fontSize: "30px"}}>CJ WRIGHT</h1>
            <p style={{fontSize: "12px"}} className='titles_team'>FOUNDER, DIRECTOR, INTERIOR ARCHITECT</p>
            <p style={{fontSize: "12px"}} className='titles_team'>(B. ARCH. + CONS. MGMT(HONS))</p>
            <br />
            <p>The technical and story-telling-brand-guy. Ten years speaking fluent hospo. Drives design from the bottom line up. Will chew your ear off about the buyer influencing power of good playlists. Designed 2 world’s top 50 cocktail bars but really more of a wine guy.</p>
          </div>
        </div>
      </div>

      {/*
      <div className="contact_section_line" data-scroll-section>
        <div className="contact_section_title" data-scroll>
          <p>What They Say</p>
        </div>
      </div>

      <div className="contact_wts" style={{paddingTop: "30px"}} data-scroll-section>
        <div className="contact_section_rebrand_wts" data-scroll data-scroll-speed={small_screen ? "1" : "3"}>
          <div style={{textAlign: "center"}} ><img src="./assets/contact/contact_02.png" width="350px"/></div>
          <h1 style={{textAlign: "center"}}>RENE REDZEPI</h1>
          <p>“...Georgina was project lead on the Noma 2.0 project at Studio David Thulstrup.
          <br/>
          <br/>
          It was a fantastic collaboration with expert execution and a hands-on approach where I never felt we were just another client. She put in the hours & the diligent commitment necessary to make any project special as opposed to just good.”</p>

          {
            small_screen ? 
            <span style={{textAlign: "center"}}>
              <h1 style={{fontSize: "25px"}}>
                HEAD CHEF | CO-OWNER<br/>
                NOMA | COPENHAGEN<br/>
                5x WORLD’S BEST RESTAURANT<br/>
                MICHELIN✻✻✻
              </h1>
            </span> 
            : 
            null
          }
          
        </div>
        <div className="contact_section_rebrand_wts" data-scroll data-scroll-speed={small_screen ? "1" : "3"}>
          <div style={{textAlign: "center"}} ><img src="./assets/contact/contact_03.png" width="350px"/></div>
          <h1 style={{fontSize: "24px", textAlign: "center"}}>LEFTOVERS GROUP</h1>
          <p>
          “...Spoiler alert! Looks Generous is going global!
          <br />
          <br />
          The dynamic duo of CJ and Georgina dared to design the future of food & beverage in Ho Chi Minh City. Thorough and innovative risk takers obsessed with the needs of their client. Leftovers group proudly calls Looks Generous allies and design professionals.
          <br />
          <br />
          Key requirements for the project were: Understanding and interpreting our financial needs and goals, fast turnover, efficient communication, cultural flexibility, intercontinental reconnaissance and remote design / project management. They exceeded our expectations, deeply cared about our project, they ‘walk the walk’ and will look to team up with Looks Generous again for future projects.”
          </p>
          
          {
            small_screen ? 
            <span style={{textAlign: "center"}}>
              <h1 style={{fontSize: "25px"}}>
                BAMBINO RESTAURANT + BAR<br/>
                ALL DAY - LATE NIGHT VENUE<br/>
                SAIGON, VIETNAM
              </h1>
            </span>
            : 
            null
          }


        </div>
        <div className="contact_section_rebrand_wts" data-scroll data-scroll-speed={small_screen ? "1" : "3"}>
          <div style={{textAlign: "center"}} ><img src="./assets/contact/contact_04.png" width="350px"/></div>
          <h1 style={{textAlign: "center"}}>JEREMY CHAN</h1>
          <p>
          “...Georgina was project lead on Ikoyi 2.0 at Studio David Thulstrup.
          <br />
          <br />
          For me, Georgina holds the key skills of a designer who works for their client, bringing to life a vision with ample knowledge, personal support and heightened sensibility for detail.”
          </p>

          {
            small_screen ? 
            <span style={{textAlign: "center"}}>
              <h1 style={{fontSize: "25px"}}>
                HEAD CHEF | CO-OWNER<br/>
                RESTAURANT IKOYI | LONDON<br/>
                #35 WORLD’S BEST RESTAURANT<br/>
                MICHELIN✻✻
              </h1>
            </span>
            : 
            null
          }
        </div>
      </div>
      <div className="contact_wts" style={{paddingTop: "30px"}} data-scroll-section id="contact_wts_bottom">
        <div className="contact_section_rebrand" style={{borderTop: '1px #fff solid'}} data-scroll data-scroll-speed={small_screen ? "1" : "3"} data-scroll-position="bottom">
          <h1>HEAD CHEF | CO-OWNER<br/>
            NOMA | COPENHAGEN<br/>
            5x WORLD’S BEST RESTAURANT<br/>
            MICHELIN✻✻✻</h1>
        </div>
        <div className="contact_section_rebrand" style={{borderTop: "1px #fff solid"}} data-scroll data-scroll-speed={small_screen ? "1" : "4"} data-scroll-position="bottom">
          <h1>BAMBINO RESTAURANT + BAR<br/>
            ALL DAY - LATE NIGHT VENUE<br/>
            SAIGON, VIETNAM</h1>
        </div>
        <div className="contact_section_rebrand" style={{borderTop: "1px #fff solid"}} data-scroll data-scroll-speed={small_screen ? "1" : "1.5"} data-scroll-position="bottom">
          <h1>HEAD CHEF | CO-OWNER<br/>
            RESTAURANT IKOYI | LONDON<br/>
            #35 WORLD’S BEST RESTAURANT<br/>
            MICHELIN✻✻</h1>
        </div>
      </div>
      <div className="contact_section_line" data-scroll-section>
        <div className="contact_section_title">
        </div>
      </div>
      */}
      <div className="contact_footer" data-scroll-section>
        <p>© LOOKS GENEROUS PTY. LTD. 2023</p>
      </div>

    </div>
    </>
    )}
    </>
  );
};

export default ContactPage;