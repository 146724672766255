import React, { useEffect } from 'react';
import useMusic from '../GeneralJSEffects/MusicEffect';
import { useEnterAnimation } from '../GeneralAnimations/EnterAnimation';

const EnterScreen = () => {
  useEnterAnimation();
  const { isMusicPlaying, toggleMusic } = useMusic("/lg_music.mp3");

  useEffect(() => {
    const enterButton = document.getElementById('enter_button');
    if (enterButton) enterButton.addEventListener('click', toggleMusic);

    return () => {
      if (enterButton) enterButton.removeEventListener('click', toggleMusic);
    };
  }, [toggleMusic]);

  useEffect(() => {
    const toggleButton = document.getElementById('toggleMusic');
    const musicOn = document.getElementById('toggleMusicOn');
    const musicOff = document.getElementById('toggleMusicOff');
    const updateButtonDisplay = () => {
      if (isMusicPlaying) {
        musicOn.style.display = 'none';
        musicOff.style.display = 'inline';
      } else {
        musicOn.style.display = 'inline';
        musicOff.style.display = 'none';
      }
    };
    updateButtonDisplay();
    const handleToggleClick = () => {
      toggleMusic();
      updateButtonDisplay();
    };
    if (toggleButton) toggleButton.addEventListener('click', handleToggleClick);
    return () => {
      if (toggleButton) toggleButton.removeEventListener('click', handleToggleClick);
    };
  }, [toggleMusic, isMusicPlaying]);

  return (
    <div className="enter_page" id="enter_page_id" style={{ opacity: 1, zIndex: 11 }}>
      <div data-content="FEEL SOUND LOOK" className="logo_cointainer" id="logo_enter">
        <img src="/assets/LG_logo.png" alt="Logo" className="logo_loader_enter" />
      </div>
      <div className="loader_text_desc" style={{ marginTop: '20px', opacity: 0 }}>
        Interior Design,{' '}
      </div>
      <div className="loader_text_desc" style={{ opacity: 0 }}>
        Brand Strategy,{' '}
      </div>
      <div className="loader_text_desc" style={{ opacity: 0 }}>
        Sound Design
      </div>
      <div className="loader_text_bottom" style={{ opacity: 0 }}>
        Melbourne, Australia
      </div>
      <div className="vertical-center-2">
        <div className="enter_button" id="enter_button">
          <div className="enter_button_div">ENTER</div>
        </div>
        <br />
        <div className="enter_button_no_music" id="enter_button_without_music" style={{ marginTop: '10px' }}>
          <div className="enter_button_div_2">Enter without music</div>
        </div>
      </div>
    </div>
  );
};

export default EnterScreen;